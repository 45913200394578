const tipoDeContatoEnum = {
  284360003: 'Aluno',
  284360000: 'Responsável Financeiro',
  284360001: 'Responsável Contratual',
  284360002: 'Responsável Pedagógico',
}

export const tipoDeContatoLabelEnum = {
  284360003: 'Aluno',
  284360000: 'Responsável Financeiro',
  284360001: 'Responsável Contratual',
  284360002: 'Responsável Pedagógico',
}

Object.freeze(tipoDeContatoEnum);
Object.freeze(tipoDeContatoLabelEnum);

export default tipoDeContatoEnum;