import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Modal } from "../modal";
import { Grid, Stack, Typography, Container, TextField, Checkbox, Button, IconButton } from "@mui/material";

import { NumberFormatBase } from 'react-number-format';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DeleteIcon from '@mui/icons-material/Delete';
import { Intervals } from "../../types/guardian-types";

export const diasSemana = [
  { name: 'Domingo', value: 'isSunday' },
  { name: 'Segunda', value: 'isMonday' },
  { name: 'Terça', value: 'isTuesday' },
  { name: 'Quarta', value: 'isWednesday' },
  { name: 'Quinta', value: 'isThursday' },
  { name: 'Sexta', value: 'isFriday' },
  { name: 'Sábado', value: 'isSaturday' },
]

const initialInterval = [
  {
    id: 1,
    startTime: '00:00:00',
    endTime: '00:00:00',
    isSunday: false,
    isMonday: false,
    isTuesday: false,
    isWednesday: false,
    isThursday: false,
    isFriday: false,
    isSaturday: false,
  }
]

interface Props {
  intervals: Intervals[];
  open: boolean;
  selectedDay: string;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  handleSave: (intervals: Intervals[]) => void;
  isEntrada: boolean;
}

const HorarioInputs = (props: Props) => {
  const { intervals, open, setOpen, isEntrada, selectedDay, handleSave } = props;
  const [selectedDayInterval, setSelectedDayInterval] = useState<Intervals[]>(initialInterval);
  const [ localIntervals, setLocalIntervals ] = useState<Intervals[]>(intervals);

  const handleLocalClose = () => {
    setSelectedDayInterval(intervals);
    setOpen(false);
  }

  const isKeySelected = (selectedDayIntervals: Intervals[], key: string = '', isAll: boolean = false) : boolean => {
    if (isAll) {
      let isAllSelected = true;
      diasSemana.forEach((dia) => {
        if (!selectedDayIntervals.every((interval) => interval[dia.value] === true)) {
          isAllSelected = false
        }
      })
      return isAllSelected
    } else {
      return selectedDayIntervals.every((interval) => interval[key] === true)
    }
  }

  const formatValue = (value: string) => {
    if (value.length === 1) {
      value = `0${value}:00:00`
    }
    if (value.length === 2) {
      value = `${value}:00:00`
    }

    return value;
  }

  useEffect(() => {
    if (open){
      const localIntervals = intervals.map((interval) => ({ ...interval }))
      setLocalIntervals(localIntervals)
    }
  }, [open, selectedDay, intervals])

  useEffect(() => {
    const filteredIntervals = localIntervals
      .filter((interval) => interval[selectedDay] === true)
    setSelectedDayInterval(filteredIntervals)
  }, [localIntervals, selectedDay])

  const TimeFormatCustom = (props: any) => {
    const format = (val: any) => {
      if (!val) return '';

      const sanitizedValue = val.replace(/[^\d]/g, '');
      let hours = sanitizedValue.substring(0, 2);
      let minutes = sanitizedValue.substring(2, 4);
      let seconds = sanitizedValue.substring(4, 6);

      if (sanitizedValue.length > 2) {
        hours = Math.min(parseInt(hours), 23).toString().padStart(2, '0');
        if (sanitizedValue.length > 3) {
          if (minutes.length > 2 || minutes[0] > 5) {
            minutes = Math.min(parseInt(minutes), 59).toString().padStart(2, '0');
          }
          if (sanitizedValue.length > 5) {
            if (seconds.length > 2 || seconds[0] > 5) {
              seconds = Math.min(parseInt(seconds), 59).toString().padStart(2, '0');
            }
            seconds = Math.min(parseInt(seconds), 59).toString().padStart(2, '0');
          }
        }
        return `${hours}:${minutes}`;
      }
      return sanitizedValue;
    };

    return <NumberFormatBase {...props} format={format} />;
  }

  const removerPeriodo = (e: MouseEvent, id: number, selectedDay: string) => {
    e.preventDefault();
    const newIntervals = localIntervals.map((interval) => {
      if (interval.id === id) {
        interval[selectedDay] = false
      }
      return interval
    })
    const filteredIntervals = newIntervals.filter((interval) => interval[selectedDay] === true)
    setSelectedDayInterval(filteredIntervals)
    setLocalIntervals(newIntervals)
  }

  const adicionarPeriodo = (e: MouseEvent, selectedDay: string) => {
    e.preventDefault();
    const newInterval = {
      id: selectedDayInterval.length + 1,
      startTime: '00:00:00',
      endTime: '00:00:00',
      isSunday: false,
      isMonday: false,
      isTuesday: false,
      isWednesday: false,
      isThursday: false,
      isFriday: false,
      isSaturday: false,
    }
    const selectedIntervals = selectedDayInterval.filter((interval) => interval[selectedDay] === true)
    // tem que verificar globalmente quais dias estão selecionados e adicionar ao novo intervalo
    diasSemana.forEach((dia) => {
      if (isKeySelected(selectedIntervals, dia.value, false)) {
        newInterval[dia.value] = true
      }
    })

    setSelectedDayInterval([...selectedDayInterval, newInterval])
    const newIntervals = [...localIntervals, newInterval]
    setLocalIntervals(newIntervals)
  }

  const handleChangeInterval = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number, selectedDay: string) => {
    event.preventDefault();
    let newIntervals = selectedDayInterval;

    const index = newIntervals.findIndex((interval) => interval.id === id);
    const name = event.target.name;
    if(name === 'startTime' || name === 'endTime') {
      event.target.value = formatValue(event.target.value)
    }
    newIntervals[index][name] = event.target.value;
    
    setSelectedDayInterval(newIntervals);
  }

  const handleMultipleChanges = (event: MouseEvent, value: boolean, isAll: boolean, selectedDay: string) => {
    event.preventDefault();
    let newIntervals = [...localIntervals];
    const name = (event.target as HTMLInputElement)?.name
    if (isAll) {
      // remover todos os intervalos que não fazem parte do dia selecionado
      newIntervals = newIntervals.filter((interval) => interval[selectedDay] !== false);
      diasSemana.forEach((dia) => {
        newIntervals.forEach((interval) => {
          interval[dia.value] = value;
        });
      });
      if (!value)
        newIntervals.forEach((interval) => {
          interval[selectedDay] = true;
        });
      setSelectedDayInterval(newIntervals);
      setLocalIntervals(newIntervals);
    } else {
      newIntervals.forEach((interval) => {
        if (interval[name]) {
          interval[name] = false;
        }
        if (interval[selectedDay]) {
          interval[name] = value;
        }
      });
      const filteredIntervals = newIntervals.filter((interval) => interval[selectedDay] === true)
      setSelectedDayInterval(filteredIntervals);
    }
  }

  const handleLocalSave = () => {
    handleSave(localIntervals);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={() => handleLocalClose()}
      title="Configuração de períodos:"
      disableExpand
      maxWidth={600}
    >
      <Container
        maxWidth="md"
        sx={{
          mt: 2,
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 150px)',
        }}
      >
        <Grid
          container
          justifyContent='flex-end'
        >
          <Grid
            item
            xs={12}
          >
            <Stack
              alignItems="flex-start"
              direction="column"
              justifyContent="space-between"
              sx={{
                mb: 2
              }}
            >
              <Stack
                direction="row"
              >
                <Typography
                  variant="h6"
                >
                  Horário de {isEntrada ? 'Entrada' : 'Saída'}:
                </Typography>
              </Stack>
            </Stack>
            {selectedDayInterval.map((interval, index) => (
              <Stack
                direction="row"
                flexWrap="wrap"
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  borderRadius: 1,
                  p: 2,
                  mb: 2
                }}
              >
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  sx={{
                    width: '100%',
                    alignItems: "center",
                    mb: 2
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textWrap: 'nowrap'
                    }}
                  >
                    Período {index + 1}:
                  </Typography>
                  <TextField
                    label="De:"
                    value={interval.startTime}
                    onChange={(event) => handleChangeInterval(event, interval.id, selectedDay)}
                    onBlur={(event) => {
                      event.target.value = formatValue(event.target.value)
                      handleChangeInterval(event, interval.id, selectedDay)
                    }}
                    name="startTime"
                    InputProps={{
                      inputComponent: TimeFormatCustom,
                    }}
                    variant="standard"
                  />
                  <HorizontalRuleIcon />
                  <TextField
                    label="Até:"
                    value={interval.endTime}
                    onChange={(event) => handleChangeInterval(event, interval.id, selectedDay)}
                    onBlur={(event) => {
                      event.target.value = formatValue(event.target.value)
                      handleChangeInterval(event, interval.id, selectedDay)
                    }}
                    name="endTime"
                    InputProps={{
                      inputComponent: TimeFormatCustom,
                    }}
                    variant="standard"
                  />
                  <IconButton
                    onClick={(e) => removerPeriodo(e, interval.id, selectedDay)}
                    sx={{
                      width: 'unset'
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Grid>
          {selectedDayInterval.length < 4 && (
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              sx={{
                mb: 3
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => adicionarPeriodo(event, selectedDay )}
                sx={{
                  mt: 2
                }}
              >
                Adicionar Período
              </Button>
            </Grid>
          )}
          {selectedDayInterval && selectedDayInterval.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                flexDirection: 'row',
              }}
            >
              <Stack
                alignItems="flex-start"
                direction="column"
                justifyContent="space-between"
                sx={{
                  mb: 2
                }}
              >
                <Typography
                  variant="h6"
                >
                  Ajustar Globalmente
                </Typography>
              </Stack>
              <Stack
                direction="row"
                flexWrap="wrap"
                sx={{
                  mb: 2,
                }}
              >
                {diasSemana.map((dia, index) => {
                  const isChecked = isKeySelected(selectedDayInterval, dia.value, false)
                  return (
                  <Stack
                    key={index}
                    direction="row"
                    sx={{
                      alignItems: "center",
                      mr: 1,
                      minWidth: 115
                    }}
                  >
                    <Checkbox
                      checked={isChecked}
                      onClick={(event) => handleMultipleChanges(event, !isChecked, false, selectedDay)}
                      name={dia.value}
                      sx={{
                        margin: '0 !important'
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        textWrap: 'nowrap'
                      }}
                    >
                      {dia.name}
                    </Typography>
                  </Stack>
                )})}
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "center"
                  }}
                >
                  <Checkbox
                    checked={isKeySelected(selectedDayInterval, '', true)}
                    onClick={(event) => handleMultipleChanges(event, !isKeySelected(selectedDayInterval, '', true), true, selectedDay)}
                    name={'isAll'}
                    sx={{
                      margin: '0 !important'
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      textWrap: 'nowrap'
                    }}
                  >
                    Todos
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: 'row',
            }}
          >
            <Stack
              alignItems="flex-end"
              direction="column"
              justifyContent="space-between"
              sx={{
                mb: 2
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleLocalSave()}
                sx={{
                  mt: 2
                }}
              >
                Salvar
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
}

export default HorarioInputs;