import React, {useEffect, useMemo} from 'react';
import {HearderApp} from './HeaderApp';
import '../styles/css/app.css';
import useSidebarMenus from "../hooks/useSidebarMenus";
import {SideNav} from "../layouts/side-nav";
import {styled} from "@mui/system";
import getAmbiente from "../utils/getAmbiente";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useMobileNav} from "../hooks/use-mobile-nav";

const SIDE_NAV_WIDTH = 290;

const VerticalLayoutRoot = styled('div')(({theme}) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

const Layout = ({children}) => {
  const opcoesMenu = useSidebarMenus();
  const [sections, setSections] = React.useState([]);
  const mobileNav = useMobileNav();

  useEffect(() => {
    if (opcoesMenu) {
      opcoesMenu.then(setSections)
    }
  }, [opcoesMenu]);

  return (
    <div>
      <div className='AjustePagina'>
        <SideNav
          color={getAmbiente()}
          sections={sections}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
        />

        <VerticalLayoutRoot>
          <VerticalLayoutContainer>
            <HearderApp
              onMobileNavOpen={mobileNav.handleOpen}
            />
            {children}
          </VerticalLayoutContainer>
        </VerticalLayoutRoot>
      </div>
    </div>
  );
}

export {Layout};