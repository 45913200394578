import React, { useEffect, useState } from 'react';
import * as Message from '../../components/shared/Message';
import * as _metodopagamentoService from '../../service/metodopagamento-service';
import * as _movimentacoesService from '../../service/movimentacoes-service.js';
import * as _blackListService from "../../service/blacklist-service";
import { Box, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Grow, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import _banks from "../../Helpers/data/banks.json";
import _bankscarol from "../../Helpers/data/bankscarol.json";
import * as Yup from 'yup';
import { Permission } from 'src/recoil/atoms/permissionsState';
import { Matricula } from 'src/components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect';
import { Unidade } from 'src/components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect';
import blockedResourcesSystem from 'src/utils/blockedResourcesSystem';
import { blockedResourcesEntitiesValues } from 'src/utils/blockedResourcesEntities';
import SaveIcon from '@mui/icons-material/Save';
import LoadingAnimation from 'src/components/LoadingAnimation/LoadigAnimation';
import { useFormik } from 'formik';
import { CreditCard, Person, RequestQuote } from '@mui/icons-material';
import withUnidadeMatricula from 'src/hocs/withUnidadeMatricula';
import csatList from 'src/utils/csatList';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';

interface Lock {
    boletoLock: boolean;
    daLock: boolean;
}


interface Props {
    Matricula: Matricula;
    Unidade: Unidade;
    permissions: Permission[];
    handleShowCsat: (key: string) => void;
}

export const TrocaMetodoPagamento = (props: Props) => {
    const [isLoadingConfirmacao, setIsLoadingConfirmacao] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingMetodo, setIsLoadingMetodo] = useState<boolean>(false);
    const [metodoAlterado, setMetodoAlterado] = useState<boolean>(false);
    const [lock, setLock] = useState<Lock>({
        boletoLock: false,
        daLock: false,
    });
    const { Matricula, Unidade, handleShowCsat } = props;
    const [metodoAtual, setMetodoAtual] = useState<string>('');
    const [novoMetodo, setNovoMetodo] = useState({
        boletobancario: false,
        debitoautomatico: false
    });
    const [opcaoSelecionada, setOpcaoSelecionada] = useState<string>('');
    const dataAreaId = Unidade.dataAreaId;
    const [bancoSelecionado, setBancoSelecionado] = useState<string>('');
    const [camposBanco, setCamposBanco] = useState<any>({
        agencia: '',
        agenciaBradesco: '',
        contaCorrente: '',
        digitoConta: ''
    });
    const [responsaveis, setResponsaveis] = useState([]);
    const [responsavelSelecionado, setResponsavelSelecionado] = useState<string>('');
    const [permiteMovimentarAvista, setPermiteMovimentarAvista] = useState<boolean>(true);


    const clearValues = () => {
        setLock({
            boletoLock: false,
            daLock: false,
        });
        setMetodoAtual('');
        setOpcaoSelecionada('');
        setNovoMetodo({
            boletobancario: false,
            debitoautomatico: false
        });
        setBancoSelecionado('');
        setCamposBanco({
            agencia: '',
            agenciaBradesco: '',
            contaCorrente: '',
            digitoConta: ''
        });
        setResponsaveis([]);
        setResponsavelSelecionado("");
        setPermiteMovimentarAvista(true);
    }

    useEffect(() => {
        const request = {
            DataAreaId: Unidade.dataAreaId,
            MatriculaId: Matricula.codigoMatriculaErp,
            TipoResponsavelMatricula: "1"
        }
        const requestResponsaveis = async () => {
            setIsLoading(true);
            try {
                clearValues();
                verificarBloqueioMovimentacao();
                const responsaveis = await _metodopagamentoService.BuscaResponsaveisMatricula(request);
                const permiteMovimentarAVista = await _movimentacoesService.PermiteMovimentarAvista(Matricula.idMatricula, Unidade.id, 284360005, 0);
                setResponsaveis(responsaveis);
                setPermiteMovimentarAvista(permiteMovimentarAVista?.value);
            } catch (error) {
                console.error(error);
                Message.ExibeMensagem('Erro ao buscar responsáveis', '2');
            } finally {
                setIsLoading(false);
            }
        }
        requestResponsaveis();
    }, [Matricula, Unidade]);

    const verificarBloqueioMovimentacao = async () => {
        try {
            const blockedResources = await _blackListService.GetList(blockedResourcesSystem.Secretaria);
            if (blockedResources && blockedResources.length) {
                const movimentacaoBoletoLock = blockedResources.find((item) =>
                    item.type === blockedResourcesEntitiesValues.Unidade &&
                    item.unidadeId === Unidade.id &&
                    item.action === "Movimentacao/Unidade/Boleto")
                if (movimentacaoBoletoLock)
                    setLock({ ...lock, boletoLock: true });

                const movimentacaoDaLock = blockedResources.find((item) =>
                    item.type === blockedResourcesEntitiesValues.Unidade &&
                    item.unidadeId === Unidade.id &&
                    item.action === "Movimentacao/Unidade/DA")

                if (movimentacaoDaLock)
                    setLock({ ...lock, daLock: true });
            }
        } catch (error) {
            console.error(error);
            Message.ExibeMensagem('Erro ao buscar responsáveis', '2');
        }
    }

    const onChangeResponsavel = async (event) => {
        try {
            const { value } = event.target;
            if (!value || value === "") return
            setResponsavelSelecionado(value);
            formik.setFieldValue('responsavelSelecionado', value);
            setIsLoadingMetodo(true);

            const request = {
                DataAreaId: Unidade.dataAreaId,
                MatriculaId: Matricula.codigoMatriculaErp,
                CustAccount: value
            }

            const metodoAtual = await _metodopagamentoService.BuscaMetodoAtual(request);
            setMetodoAtual(metodoAtual);
            setNovoMetodo({
                boletobancario: false,
                debitoautomatico: false
            });
            let campos = camposBanco;
            campos["agencia"] = "";
            campos["agenciaBradesco"] = "";
            campos["contaCorrente"] = "";
            campos["digitoConta"] = "";
            setCamposBanco(campos);
        } catch (error) {
            console.error(error);
            Message.ExibeMensagem('Erro ao buscar responsáveis', '2');
        } finally {
            setIsLoadingMetodo(false);
        }
    }

    const handleChangeBank = (event) => {
        const { value } = event.target;
        setBancoSelecionado(value);
        formik.setFieldValue('bancoSelecionado', value);
    }

    const handleValueChange = (event) => {
        try {
            const { value } = event.target;
            setOpcaoSelecionada(value);
            if (value === 'Boleto_Bancario') {
                setNovoMetodo({
                    boletobancario: true,
                    debitoautomatico: false
                });
                let campos = camposBanco;
                campos["agencia"] = "";
                campos["agenciaBradesco"] = "";
                campos["contaCorrente"] = "";
                campos["digitoConta"] = "";
                setCamposBanco(campos);
            } else {
                setNovoMetodo({
                    boletobancario: false,
                    debitoautomatico: true
                });
            }
        } catch (error) {
            console.error(error);
            Message.ExibeMensagem('Erro ao buscar responsáveis', '2');
        }
    }

    const handleDigitoChange = (event) => {
        const { value, name } = event.target;
        const item = camposBanco;

        var regex = /\d+/g;
        var result = "";
        var returnRegex = value.toString().match(regex);

        if (returnRegex !== null && returnRegex !== undefined && !(name === "digitoConta" && (value.toString() === "X" || event.target.value.toString() === "x"))) {
            result = returnRegex.join('');
        }
        if (item[name] !== undefined && item[name] != null) {
            item[name] = result;
        }

        //Regra especial para contemplar o "X" como exceção para digito
        if (name === "digitoConta" && (value.toString() === "X" || value.toString() === "x")) {
            item[name] = event.target.value.toString();
        }

        formik.setFieldValue(name, item[name]);
        setCamposBanco(item);
    }

    const handleChange = (event) => {
        let campos = camposBanco;
        const { name, value } = event.target;
        if (name === "contaCorrente") {
            campos[name] = value.replace(/[^0-9]/g, "");
        }
        else {
            campos[name] = value;
        }
        formik.setFieldValue(name, campos[name]);
        setCamposBanco(campos);
    }


    const validationSchema = Yup.object({
        responsavelSelecionado: Yup.string().required("Campo obrigatório"),
    });

    const debitoValidationSchema = Yup.object({
        contaCorrente: Yup.string().required("Campo obrigatório"),
        agencia: Yup.string().nullable().test('agencia', 'Campo obrigatório', val => {
            if (bancoSelecionado !== "237") {
                if (!val || val === "") {
                    return false;
                }
                return true;
            } else {
                return true;
            }
        }),
        agenciaBradesco: Yup.string().nullable().test('agenciaBradesco', 'Campo obrigatório', val => {
            if (bancoSelecionado === "237") {
                if (!val || val === "") {
                    return false;
                }
                return true;
            } else {
                return true;
            }
        }),
        digitoConta: Yup.string().required("Campo obrigatório"),
        responsavelSelecionado: Yup.string().required("Campo obrigatório"),
    });

    const handleChangeAgenciaComDigito = (e) => {
        const { name, value } = e.target;
        let contaCorrente = value.replace(/[^0-9]/g, "");
        contaCorrente = contaCorrente.replace(/(\d{4})(\d{1})/, "$1-$2");
        if (contaCorrente.length > 6) return;
        let campos = camposBanco;
        campos[name] = contaCorrente;
        formik.setFieldValue(name, campos[name]);
        setCamposBanco(campos);
    }

    const formik = useFormik({
        initialValues: { ...camposBanco, responsavelSelecionado, bancoSelecionado },
        validationSchema: novoMetodo.debitoautomatico ? debitoValidationSchema : validationSchema,
        onSubmit: async (values) => {
            let request = {};
            let agencia = "";
            let digitoAgencia = "";
            if (opcaoSelecionada !== "Boleto_Bancario") {
                if (bancoSelecionado === "237") {
                    if (camposBanco.agenciaBradesco.replace("_", "").trim().length < 6) {
                        Message.ExibeMensagem("Agência inválida.", 1, true);
                        return;
                    }

                    var valorAgencia = camposBanco.agenciaBradesco.replace("_", "").trim().split("-");
                    agencia = valorAgencia[0];
                    digitoAgencia = valorAgencia[1];
                } else {
                    if (camposBanco.agencia.replace("_", "").trim().length < 4) {
                        Message.ExibeMensagem("Agência inválida.", 1, true);
                        return;
                    }
                    agencia = camposBanco.agencia.replace("_", "").trim();
                    digitoAgencia = "";
                }

                request = {
                    DataAreaId: dataAreaId,
                    MatriculaId: Matricula?.codigoMatriculaErp,
                    CustAccount: responsavelSelecionado,
                    meioPagamento: 284360001,
                    DadosPagamentoDebitoAutomatico: {
                        debitoAutomaticoBanco: bancoSelecionado,
                        debitoAutomaticoAgencia: agencia,
                        debitoAutomaticoAgenciaDigito: digitoAgencia,
                        debitoAutomaticoConta: camposBanco.contaCorrente.trim(),
                        debitoAutomaticoContaDigito: camposBanco.digitoConta.trim()
                    }
                };
            } else {
                request = {
                    DataAreaId: dataAreaId,
                    MatriculaId: Matricula?.codigoMatriculaErp,
                    CustAccount: responsavelSelecionado,
                    meioPagamento: 284360002
                };
            }
            setIsLoadingConfirmacao(true);
            _movimentacoesService.getTemplateMovimentacao(Unidade.id, 284360005).then(({ diaLimiteMes }) => {
                if (diaLimiteMes) {
                    const diaAtual = parseInt(moment().tz("America/Sao_Paulo").format('DD/MM/YYYY').split("/")[0]);
                    const passouDiaLimite = diaAtual >= diaLimiteMes ? true : false;
                    if (passouDiaLimite) {
                        Swal.fire({
                            title: 'A data limite para alteração de método de pagamento deste serviço é dia: ' + diaLimiteMes + ". A parcela para o próximo mês permanecerá a mesma(as demais serão ajustadas). Por favor, confirme se deseja prosseguir",
                            text: "Esta ação não poderá ser desfeita!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Sim, Continuar!',
                            showLoaderOnConfirm: true,
                            allowOutsideClick: () => !Swal.isLoading(),
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                _metodopagamentoService.TrocarMetodoPagamento(request).then((result) => {
                                    if (result === undefined) {
                                        Message.ExibeMensagem("Erro ao processar a alteração de método.", '2', true);
                                        setIsLoadingConfirmacao(false);
                                        setMetodoAlterado(false);
                                    } else {
                                        if (result.statusCode !== 0) {
                                            Message.ExibeListMensagem(result.messages, true, "");
                                            setIsLoadingConfirmacao(false);
                                            setMetodoAlterado(false);
                                        } else {

                                            Message.ExibeMensagem("Alteração realizada com sucesso.", '0', true);
                                            setIsLoadingConfirmacao(false);
                                            setMetodoAlterado(true);
                                        }
                                    }
                                }).finally(() => {
                                    setIsLoadingConfirmacao(false);
                                    if (handleShowCsat) handleShowCsat(csatList.trocaMetodoPagamento)
                                });
                            } else {
                                setIsLoadingConfirmacao(false);
                                setMetodoAlterado(false);
                            }
                        })
                    } else {
                        _metodopagamentoService.TrocarMetodoPagamento(request).then((result) => {
                            if (result === undefined) {
                                Message.ExibeMensagem("Erro ao processar a alteração de método.", '2', true);
                                setIsLoadingConfirmacao(false);
                                setMetodoAlterado(false);
                            } else {
                                if (result.statusCode !== 0) {
                                    Message.ExibeListMensagem(result.messages, true, "");
                                    setIsLoadingConfirmacao(false);
                                    setMetodoAlterado(false);
                                } else {
                                    Message.ExibeMensagem("Alteração realizada com sucesso.", '0', true);
                                    setIsLoadingConfirmacao(false);
                                    setMetodoAlterado(false);
                                }
                            }
                        }).finally(() => {
                            setIsLoadingConfirmacao(false);
                            if (handleShowCsat) handleShowCsat(csatList.trocaMetodoPagamento);
                        });
                    }
                }
            })
        }
    });

    if (!permiteMovimentarAvista)
        return (
            <div >
                <div className=" form-group d-flex justify-content-center">
                    <h3 style={{ color: '#0068b2' }}> <strong>AVISO</strong></h3>
                </div>
                <div className=" form-group d-flex justify-content-center">
                    <h5 style={{ color: '#0068b2' }}> O Template selecionado não permite movimentações onde o pagamento é Avista.</h5>
                </div>
            </div>
        )

    if (metodoAlterado)
        return (
            <div >
                <div className=" form-group d-flex justify-content-center">
                    <h3 style={{ color: '#0068b2' }}> <strong>MÉTODO DA MATRÍCULA {Matricula?.codigoMatricula} ALTERADO! </strong>
                    </h3>
                </div>
            </div>
        );

    if (isLoading)
        return <LoadingAnimation />

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
            }}
        >
            <form
                onSubmit={formik.handleSubmit}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems='center'
                    spacing={3}
                    sx={{
                        py: 4,
                        mb: 3
                    }}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Stack
                            spacing={1}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            sx={{
                                mb: 4
                            }}
                        >
                            <Typography variant="h4">
                                Informações da Matrícula
                            </Typography>
                            <Person
                                sx={{
                                    fontSize: '2rem',
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                    >
                        <InputLabel id="estudante_Name">
                            Nome
                        </InputLabel>
                        <TextField
                            fullWidth
                            label="Nome"
                            name="estudante_Name"
                            size="small"
                            value={Matricula?.estudante_Name}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                '& .MuiInputBase-input': {
                                    textOverflow: 'ellipsis'
                                }
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <InputLabel id="codigoMatricula">
                            Matrícula
                        </InputLabel>
                        <TextField
                            fullWidth
                            label="Matrícula"
                            name="codigoMatricula"
                            size="small"
                            value={Matricula?.codigoMatricula}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                '& .MuiInputBase-input': {
                                    textOverflow: 'ellipsis'
                                }
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                    >
                        <Stack>
                            <InputLabel id="responsavel">
                                Responsável
                            </InputLabel>
                            <Select
                                name='responsaveis'
                                fullWidth
                                variant="filled"
                                defaultValue=""
                                value={formik.values.responsavelSelecionado}
                                disabled={isLoadingMetodo}
                                size='small'
                                onChange={(e) => onChangeResponsavel(e)}
                            >
                                {isLoadingMetodo ?
                                    <CircularProgress color="primary" size={20} />
                                    :
                                    <MenuItem value="">
                                        <em>Selecione o Responsável</em>
                                    </MenuItem>
                                }
                                {responsaveis.map((item) => (
                                    <MenuItem key={item.responsavel} value={item.responsavel}>{item.responsavel + ' - ' + item.nomeResponsavel}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <InputLabel id="metodoAtual">
                            Método Atual do Responsável
                        </InputLabel>
                        <TextField
                            fullWidth
                            name="metodoAtual"
                            size="small"
                            value={metodoAtual}
                            variant="filled"
                            sx={{
                                '& .MuiInputBase-input': {
                                    textOverflow: 'ellipsis'
                                }
                            }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Grow
                            in={!(metodoAtual.indexOf("parcelas abertas") > 0 || metodoAtual.indexOf("método não cadastrado") > 0 || metodoAtual === "")}
                        >
                            <Grid
                                container
                                justifyContent="center"
                                alignItems='center'
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Stack
                                        spacing={1}
                                        direction={'row'}
                                        justifyContent={'flex-start'}
                                        alignItems={'center'}
                                        sx={{
                                            mt: 4,
                                            mb: 4
                                        }}
                                    >
                                        <Typography variant="h5">
                                            Alteração de Método de Pagamento
                                        </Typography>
                                        <CreditCard color='info' />
                                        <RequestQuote color='info' />
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        spacing={3}
                                        sx={{
                                            mb: 3
                                        }}
                                    >
                                        <FormGroup
                                            sx={{
                                                flexDirection: "row"
                                            }}
                                        >
                                            {!lock.boletoLock &&
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={novoMetodo.boletobancario}
                                                            onClick={handleValueChange}
                                                            name="BoletoBancario"
                                                            value="Boleto_Bancario"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Boleto Bancário"
                                                />
                                            }
                                            {!lock.daLock &&
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={novoMetodo.debitoautomatico}
                                                            name="DebitoAutomatico"
                                                            value="Debito_Bancario"
                                                            onClick={handleValueChange}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Débito Automático"
                                                />
                                            }
                                        </FormGroup>
                                    </Stack>
                                    {novoMetodo.debitoautomatico &&
                                        <Grow
                                            in={novoMetodo.debitoautomatico}
                                        >
                                            <Card
                                                sx={{
                                                    p: 2,
                                                    mb: 3
                                                }}
                                            >
                                                <CardHeader
                                                    title="Dados Bancários"
                                                />
                                                <CardContent
                                                    sx={{
                                                        p: 2
                                                    }}
                                                >
                                                    <Stack>
                                                        <InputLabel id="banco">
                                                            Selecione o Banco
                                                        </InputLabel>
                                                        <Select
                                                            fullWidth
                                                            label="Banco"
                                                            defaultValue=''
                                                            name="banco"
                                                            size="small"
                                                            variant="filled"
                                                            value={formik.values.bancoSelecionado}
                                                            onChange={handleChangeBank}
                                                            onBlur={formik.handleBlur}
                                                            error={!!(formik.touched.bancoSelecionado && formik.errors.bancoSelecionado)}
                                                        >
                                                            <MenuItem value="">
                                                                <em>Selecione o Banco</em>
                                                            </MenuItem>
                                                            {dataAreaId !== "ECP" && dataAreaId !== "ECR" ?
                                                                _banks.map((item) => (
                                                                    <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
                                                                ))
                                                                :
                                                                _bankscarol.map((item) => (
                                                                    <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Stack>
                                                    <Stack
                                                        direction={'row'}
                                                        spacing={3}
                                                        sx={{
                                                            mt: 2
                                                        }}
                                                    >
                                                        {bancoSelecionado !== "237" ?
                                                            <TextField
                                                                fullWidth
                                                                label="Agência"
                                                                name="agencia"
                                                                size="small"
                                                                variant="filled"
                                                                disabled={!bancoSelecionado}
                                                                value={formik.values.agencia}
                                                                onBlur={formik.handleBlur}
                                                                onChange={(e) => {
                                                                    const { value } = e.target;
                                                                    if (value.length <= 4) {
                                                                        handleChange(e);
                                                                    }
                                                                }}
                                                                error={!!(formik.touched.agencia && formik.errors.agencia)}
                                                                helperText={String((formik.touched.agencia && formik.errors.agencia) ?? "")}
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        textOverflow: 'ellipsis'
                                                                    }
                                                                }}
                                                            />
                                                            :
                                                            <TextField
                                                                fullWidth
                                                                label="Agência com o dígito"
                                                                name="agenciaBradesco"
                                                                size="small"
                                                                variant="filled"
                                                                disabled={!bancoSelecionado}
                                                                value={formik.values.agenciaBradesco}
                                                                onBlur={formik.handleBlur}
                                                                onChange={handleChangeAgenciaComDigito}
                                                                error={!!(formik.touched.agenciaBradesco && formik.errors.agenciaBradesco)}
                                                                helperText={String((formik.touched.agenciaBradesco && formik.errors.agenciaBradesco) ?? "")}          
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        textOverflow: 'ellipsis'
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        <TextField
                                                            fullWidth
                                                            label="Número da Conta"
                                                            name="contaCorrente"
                                                            size="small"
                                                            variant="filled"
                                                            value={formik.values.contaCorrente}
                                                            disabled={!bancoSelecionado}
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                if (value.length <= 20) {
                                                                    handleChange(e);
                                                                }
                                                            }}
                                                            error={!!(formik.touched.contaCorrente && formik.errors.contaCorrente)}
                                                            helperText={String((formik.touched.contaCorrente && formik.errors.contaCorrente) ?? "")}
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    textOverflow: 'ellipsis'
                                                                }
                                                            }}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            label="Dígito da Conta"
                                                            name="digitoConta"
                                                            size="small"
                                                            disabled={!bancoSelecionado}
                                                            variant="filled"
                                                            value={formik.values.digitoConta}
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                if (value.length > 1) 
                                                                    return
                                                                handleDigitoChange(e);
                                                            }}
                                                            error={!!(formik.touched.digitoConta && formik.errors.digitoConta)}
                                                            helperText={String((formik.touched.digitoConta && formik.errors.digitoConta) ?? "")}
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    textOverflow: 'ellipsis'
                                                                }
                                                            }}
                                                        />
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                        </Grow>
                                    }
                                    <Stack>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                isLoadingConfirmacao || 
                                                (!novoMetodo.boletobancario && !novoMetodo.debitoautomatico) || 
                                                (novoMetodo.debitoautomatico && !bancoSelecionado) ||
                                                !formik.dirty
                                            }
                                            startIcon={isLoadingConfirmacao ? <CircularProgress color="primary" size={20}/> : <SaveIcon/>}
                                        >
                                            {isLoadingConfirmacao ? 'Aguarde...' : 'Confirmar Alteração'}
                                        </Button>
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Grow>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default withUnidadeMatricula(TrocaMetodoPagamento, "Troca de Método de Pagamento", csatList.trocaMetodoPagamento);