import { Unidade } from 'src/components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect';
import { Matricula } from 'src/components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect';
import { Permission } from 'src/recoil/atoms/permissionsState';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import * as Message from '../../components/shared/Message';
import * as _planoPagamentoService from '../../service/planoPagamento-service.js';
import * as _movimentacoesService from '../../service/movimentacoes-service.js';
import StepWizard from "react-step-wizard";
import csatList from "../../utils/csatList";
import { useEffect, useState } from 'react';
import LoadingAnimation from 'src/components/LoadingAnimation/LoadigAnimation';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, FormControl, Grid, MenuItem, Select, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import ErrorMessage from 'src/components/ErrorMessage/ErrorMessage';
import { gql, useQuery } from "@apollo/client";

interface Props {
    Matricula: Matricula;
    Unidade: Unidade;
    permissions: Permission[];
    handleShowCsat: (key: string) => void;
    listResponsaveis: any[];
}

interface ServData {
    idServico?: string;
    qntParcela?: number
}

interface InitialState {
    matriculaId: string;
    servicoRegular: ServData;
    servicosObrigatorios?: ServData[];
    servicosComplementares: any[];
    codigoResponsavelErp: string;
}

const initialState: InitialState = {
    matriculaId: '',
    servicoRegular: {
        idServico: '',
        qntParcela: 0,
    },
    servicosObrigatorios: [],
    servicosComplementares: [],
    codigoResponsavelErp: '',
}

const PlanoDePagamento = (props: Props) => {
    const { Matricula, Unidade, handleShowCsat } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingParcela, setIsLoadingParcela] = useState<boolean>(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [selectedResponsavel, setSelectedResponsavel] = useState<string | null>(null);
    const [permiteMovimentarAvista, setPermiteMovimentarAvista] = useState<boolean>(true);
    const [errorRequestMessage, setErrorRequestMessage] = useState<string | null>("Sem opção de Parcelamento");
    const [request, setRequest] = useState<InitialState>(initialState);
    const [data, setData] = useState<any>(null);

    const {
        data: dataResponsaveis,
        loading
    } = useQuery(gql`
          query ListResponsaveis($idMatricula: ID) {
              listResponsaveis(idMatricula: $idMatricula) {
                  codigoErp
                  nome
              }
          }
      `, {
        skip: !Matricula,
        variables: {
            idMatricula: Matricula?.idMatricula
        }
    })
    const {
        listResponsaveis
    } = dataResponsaveis || {}


    useEffect(() => {
        const fetchData = async () => {
            setRequest(initialState);
            setSelectedResponsavel(null);
            setIsLoading(true);
            try {
                const request = {
                    matriculaId: Matricula.idMatricula,
                    servicoRegular: {
                        qntParcela: 0,
                    },
                    servicosObrigatorios: [],
                    servicosComplementares: [],
                    tipoMovimentacao: 284360004,
                    codigoResponsavelErp: selectedResponsavel
                }
                await buscarValores(request);
                const result = await _movimentacoesService.PermiteMovimentarAvista(Matricula.idMatricula, Unidade.id, 284360004, 0);
                setPermiteMovimentarAvista(result.value);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();

    }, [Matricula, Unidade]);

    const buscarValores = async (request: InitialState) => {
        setIsLoadingParcela(true);
        try {
            const result = await _planoPagamentoService.ValoresReparcelamentoServicos(Matricula.idMatricula, request);
            if (result && result.statusCode === 0 && result.value) {
                const { value } = result;
                if (!value.servicoPrincipal || !value.servicoPrincipal.servicoId)
                    return;
                setData(value)
                const obrigatorios = value.obrigatorios.map((serv) => {
                    return {
                        idServico: serv.servicoId,
                        qntParcela: parseInt(serv.parcelamentoNovo),
                    }
                })
                setRequest({
                    ...request,
                    servicoRegular: {
                        idServico: value.servicoPrincipal.servicoId,
                        qntParcela: parseInt(value.servicoPrincipal.parcelamentoNovo)
                    },
                    servicosObrigatorios: obrigatorios,
                    servicosComplementares: [],
                });
                setErrorRequestMessage("Sem opção de Parcelamento");
            } else if (result && result.messages && result.messages.length) {
                setErrorRequestMessage(result.messages[0].text);
                setData(null);
            }
        } catch (error) {
            setErrorRequestMessage("Sem opção de Parcelamento");
            setData(null);
        } finally {
            setIsLoadingParcela(false);
        }
    };

    const mudarResponsavelFinanceiro = async (codigoErp) => {
        setSelectedResponsavel(codigoErp);
        const newRequest = {
            ...request,
            codigoResponsavelErp: codigoErp
        }
        setRequest(newRequest);
        await buscarValores(newRequest);
    }

    const verificaSeTemPlano = (data) => {
        let count = 0;
        if (!data) return false;
        if (data.obrigatorios.length) {
            if (data.obrigatorios.find(x => x?.detalhes.length))
                count++;
        }
        if (data.servicoPrincipal?.detalhes?.length)
            count++;

        return count > 0
    }

    const mudarValoresCalculados = async () => {
        setIsLoadingSubmit(true);
        try {
            const result = await _planoPagamentoService.ChangeCalculatorValues(Matricula.idMatricula, request);
            if (result && result.value)
                Message.ExibeMensagem(result.value, "0", true);
            else
                Message.ExibeMensagem("Erro ao alterar valores", "0", true);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingSubmit(false);
            handleShowCsat(csatList.trocaPlanoPagamento);
        }
    }
    if (isLoading || loading) {
        return <LoadingAnimation />
    }
    if (!permiteMovimentarAvista) {
        return (
            <div>
                <div className=' form-group d-flex justify-content-center'>
                    <h3 style={{ color: '#0068b2' }}>
                        <strong>AVISO</strong>
                    </h3>
                </div>
                <div className=' form-group d-flex justify-content-center'>
                    <h5 style={{ color: '#0068b2' }}>
                        {' '}
                        O Template selecionado não permite movimentações onde o pagamento
                        é Avista.
                    </h5>
                </div>
            </div>
        )
    }
    if (listResponsaveis && listResponsaveis.length === 1 && listResponsaveis[0].codigoErp !== selectedResponsavel) {
        mudarResponsavelFinanceiro(listResponsaveis[0].codigoErp);
    }
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
            }}
        >
            <Grid
                container
                justifyContent="center"
                alignItems='center'
                spacing={3}
                sx={{
                    py: 4,
                    mb: 3
                }}
            >
                <Grid
                    item
                    xs={12}
                >
                    {data && verificaSeTemPlano(data) && listResponsaveis && listResponsaveis.length && (
                        <Stack
                            spacing={1}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            sx={{
                                mb: 2
                            }}
                        >
                            <Typography variant="h6">
                                Informações do
                            </Typography>
                            <Typography variant="h6">
                                <strong>Aluno(a): </strong> {Matricula.estudante_Name}
                            </Typography>
                        </Stack>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    {data && verificaSeTemPlano(data) && listResponsaveis && listResponsaveis.length && (
                        <FormControl fullWidth>
                            <Typography variant="h6">
                                Responsável Financeiro
                            </Typography>
                            <Select
                                label="Selecione um responsável"
                                placeholder='Selecione um responsável"'
                                id="responsavel"
                                fullWidth
                                value={selectedResponsavel}
                                disabled={isLoading || isLoadingParcela || isLoadingSubmit || loading}
                                onChange={(e) => mudarResponsavelFinanceiro(e.target.value)}
                            >
                                <MenuItem value="">Selecione um responsável</MenuItem>
                                {listResponsaveis.map((responsavel) => (
                                    <MenuItem value={responsavel.codigoErp}>
                                        {responsavel.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    {data && !isLoading && verificaSeTemPlano(data) ? (
                        <StepWizard>
                            {data.servicoPrincipal?.detalhes?.length > 0 && (
                                <Step
                                    data={data.servicoPrincipal}
                                    setData={setData}
                                    name="serviço principal"
                                    property={"servicoRegular"}
                                    collapsed={true}
                                    // setCollapsed={this.collapse}
                                    request={request}
                                    setRequest={buscarValores}
                                    matricula={Matricula}
                                    changeCalculatorValues={mudarValoresCalculados}
                                    loading={isLoadingParcela}
                                    loadingSalvar={isLoadingSubmit}
                                />
                            )}
                            {data.obrigatorios.map(
                                (obrigatorio) =>
                                    obrigatorio?.detalhes?.length > 0 && (
                                        <Step
                                            data={obrigatorio}
                                            name={obrigatorio?.nome ?? "serviços obrigatórios"}
                                            collapsed={true}
                                            property={"servicosObrigatorios"}
                                            request={request}
                                            setRequest={buscarValores}
                                            matricula={Matricula}
                                            changeCalculatorValues={mudarValoresCalculados}
                                            loading={isLoadingParcela}
                                            loadingSalvar={isLoadingSubmit}
                                        />
                                    )
                            )}
                        </StepWizard>
                    ) : (
                        !isLoadingParcela &&
                        <ErrorMessage
                            title={errorRequestMessage}
                            message={null}
                            info
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

const Step = ({ ...props }) => {
    const [parcelas, setParcelas] = useState(0);

    useEffect(() => {
        const anoLetivo = parseInt(props.matricula.anoLetivo_Name);
        if (anoLetivo === new Date().getFullYear()) {
            setParcelas((new Date().getMonth() + 1) * -1 + 12);
        } else if (anoLetivo > new Date().getFullYear()) {
            setParcelas(12);
        }
        updateRequest(0);
    }, [props.matricula]);

    const updateRequest = (quantidadeParcelas) => {
        const newRequest = props.request;

        if (Array.isArray(newRequest[props.property])) {
            let servico = {
                qntParcela: 0,
            };
            let servicos = newRequest[props.property].filter(
                (x) => x.idServico !== props.data.servicoId
            );
            servico = newRequest[props.property].find(
                (x) => x.idServico === props.data.servicoId
            );

            servico.qntParcela = parseInt(quantidadeParcelas);
            servicos.push(servico);
            props.setRequest({ ...props.request, [props.property]: servicos });
        } else {
            let servico = {
                qntParcela: 0,
            };
            servico = newRequest[props.property];
            servico.qntParcela = parseInt(quantidadeParcelas)
            props.setRequest({ ...props.request, [props.property]: servico });
        }
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                        {props.currentStep}
                    </Avatar>
                }
                title={"Abaixo, segue proposta de plano de pagamento de " + props.data?.nome}
            />
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems='center'
                    spacing={2}
                    sx={{
                        py: 4,
                        mb: 3
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                padding: '10px',
                                marginBottom: '14px'
                            }}
                        >
                            <Typography variant="h6">
                                Plano Atual
                            </Typography>
                        </Box>
                        <FormControl
                            fullWidth
                        >
                            <TextField
                                label="Parcelas"
                                value={props.data?.parcelamentoAnterior}
                                fullWidth
                                disabled
                                inputProps={{ readOnly: true }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                label="Valor"
                                value={props.data?.detalhes?.reverse().find(x => x.valorAnterior > 0)?.valorAnterior?.toFixed(2)}
                                fullWidth
                                disabled
                                inputProps={{ readOnly: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                padding: '10px',
                                marginBottom: '14px'
                            }}
                        >
                            <Typography variant="h6">
                                Plano Novo
                            </Typography>
                        </Box>
                        <FormControl fullWidth>
                            <Select
                              label="Quantidade de Parcelas"
                              placeholder='Quantidade de Parcelas'
                              id="parcelas"
                              fullWidth
                              disabled={props.loading || props.loadingSalvar}
                              onChange={(e) => updateRequest(e.target.value)}
                            >
                                <MenuItem value={0}>Á Vista</MenuItem>
                                <MenuItem value={props.data?.parcelamentoAnterior}>{props.data?.parcelamentoAnterior}x</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                label="Valor"
                                value={props.data?.detalhes?.reverse().find(x => x.valorNovo > 0)?.valorNovo?.toFixed(2)}
                                fullWidth
                                disabled
                                inputProps={{ readOnly: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {props.loading ?
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={200}
                        width={"100%"}
                    /> :
                    <Accordion
                        sx={{
                            width: '100%',
                            borderRadius: '5px',
                            mb: 3
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownward />}
                            aria-controls='panel-content'
                            id='panel-header'
                            sx={{
                                borderRadius: '5px',
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            }}
                        >
                            <Typography>
                                Detalhes
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                        >
                            <DataGrid
                                rows={props.data?.detalhes.map((detalhe, index) => {
                                    return {
                                        ...detalhe,
                                        id: index
                                    }
                                }) ?? []}
                                columns={[
                                    {
                                        field: 'numParcela',
                                        headerName: 'Parcela',
                                        minWidth: 100,
                                        flex: 1,
                                    },
                                    {
                                        field: 'valorAnterior',
                                        headerName: 'Valor Anterior',
                                        minWidth: 200,
                                        flex: 1,
                                        renderCell: (params) => (
                                            <strong>
                                                {params.value > 0 ? `R$ ${params.value.toFixed(2)}` : "-"}
                                            </strong>
                                        )
                                    },
                                    {
                                        field: 'valorNovo',
                                        headerName: 'Valor Novo',
                                        minWidth: 200,
                                        flex: 1,
                                        renderCell: (params) => (
                                            <strong>
                                                {params.value > 0 ? `R$ ${params.value.toFixed(2)}` : "-"}
                                            </strong>
                                        )
                                    }
                                ]}
                                hideFooterPagination
                                hideFooter
                                disableColumnMenu
                                disableColumnSelector
                            />
                        </AccordionDetails>
                    </Accordion>
                }
                <CardActions>
                    {props.currentStep > 1 && (
                        <Button
                            disabled={props.loading || props.loadingSalvar}
                            className="primary finish"
                            onClick={() => props.previousStep()}
                            variant='contained'
                        >
                            Voltar
                        </Button>
                    )}
                    {props.currentStep === props.totalSteps ? (
                        <Button
                            disabled={props.loading || props.loadingSalvar}
                            className="primary finish"
                            onClick={() => props.changeCalculatorValues()}
                            variant='contained'
                        >
                            {props.loadingSalvar ? "Salvando..." : "Alterar"}
                        </Button>
                    ) : (
                        <Button
                            disabled={props.loading || props.loadingSalvar}
                            className="primary finish"
                            onClick={() => props.nextStep()}
                            variant='contained'
                        >
                            Próximo serviço
                        </Button>
                    )}
                </CardActions>

            </CardContent>
        </Card>
    );
}

export default withUnidadeMatricula(PlanoDePagamento, "Plano de Pagamento", csatList.trocaPlanoPagamento);