import React, {Component} from "react";
import '../../styles/css/app.css';
import {
  Bank2,
  FileEarmarkMedical,
  FileEarmarkMedicalFill,
  HandbagFill,
  PersonFill,
  ReceiptCutoff
} from 'react-bootstrap-icons';
import Icon from '@mdi/react';
import {mdiAccountGroup, mdiEmoticon, mdiEmoticonNeutral, mdiEmoticonSad, mdiStar, mdiStarOutline} from '@mdi/js';

import * as _matriculaService from '../../service/matricula-service';
import * as _util from '../../Helpers/utils'
import LoadingAnimation from "../../components/LoadingAnimation/LoadigAnimation";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import Box from "@mui/material/Box";
import {GetByContatoMatriculaCRM} from "../../service/contatos-service";
import Stack from "@mui/material/Stack";
import {Divider} from "@mui/material";
import tipoDeContatoEnum from "../../utils/tipoDeContatoEnum";

class Matricula extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MatriculaGeral: {},
      responsaveis: [],
      isLoading: false
    }
  }

  async componentDidMount() {
    this.buscaMatriculaGeral();
    this.buscaResponsaveis();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.Matricula.idMatricula !== this.props.Matricula.idMatricula) {
      this.buscaMatriculaGeral();
      this.buscaResponsaveis();
    }
  }

  buscaResponsaveis() {
    GetByContatoMatriculaCRM(this.props.Matricula.idMatricula).then(res => {
      this.setState({responsaveis: (res ?? []).filter(x => x.id !== this.props.Matricula.idAluno)});
    });
  }

  buscaMatriculaGeral() {
    this.setState({isLoading: true});
    _matriculaService.GetMatriculaGeral(this.props.Matricula.idMatricula).then(matriculaGeral => {
      this.setState({
        MatriculaGeral: matriculaGeral ?? {},
        isLoading: false,
        Matricula: this.props.Matricula
      });
    })
  }


  renderOnda = (onda) => {
    const waves = {
      1: '1ª Onda',
      2: '2ª Onda',
    }

    return waves[onda] ?? '--';
  }

  renderAdimplenteScore = (s) => {
    const score = parseInt(s);
    let label = 'Sem dados';
    let star = 0;

    if (score >= 30) {
      label = 'Muito Baixo';
      star = 0;
    } else if (score >= 20 && score < 30) {
      label = 'Baixo';
      star = 1;
    } else if (score >= 10 && score < 20) {
      label = 'Regular';
      star = 2;
    } else if (score >= 1 && score < 10) {
      label = 'Bom';
      star = 3;
    } else if (score === 0) {
      label = 'Muito Bom';
      star = 4;
    }

    const stars =Array.from({length: 5}, (_, index) => {
      return (
        <Icon color="#ffbd00" path={index <= star ? mdiStar : mdiStarOutline} size="20px"/>
      )
    })

    return (
      <Stack
        direction="row"
        spacing={0}
        alignItems="center"
      >
        {stars}
        <Box
          sx={{
            pl: 1,
            fontSize: '12px'
          }}
        >
          {label}
        </Box>
      </Stack>
    )
  }

  renderNpsScoreLabel = (score) => {
    const label = {
      0: 'Detrator',
      1: 'Detrator',
      2: 'Detrator',
      3: 'Detrator',
      4: 'Detrator',
      5: 'Detrator',
      6: 'Detrator',
      7: 'Neutro',
      8: 'Neutro',
      9: 'Promotor',
      10: 'Promotor',
    }

    return label[score] ?? 'Não respondido';
  }

  render() {
    const {MatriculaGeral, isLoading} = this.state;
    if (isLoading) {
      return <LoadingAnimation/>
    } else {
      const {
        aluno = {},
        matricula = {},
        unidade = {},
        servicoUnidade = {},
        historicoDeBolsas = [],
        bolsaVigente = {},
        descricaoValorTotal,
        servicoMatriculaList = []
      } = MatriculaGeral ?? {};
      const {
        nome,
        codigoErp,
        dataNascimento,
        rg,
        cpf,
        telefoneFixo
      } = aluno ?? {};
      const {
        codigoMatricula,
        codigoMatriculaErp,
        razaoStatus_Nome,
        dataDaMatricula,
        dataDeCancelamento,
        motivoDoCancelamento
      } = matricula ?? {};
      const {
        codigoERP: unidadeCodigoErp,
        nome: unidadeNome
      } = unidade ?? {};
      const {
        codigo: servicoCodigo,
        nome: servicoNome,
        dataInicio: servicoDataInicio,
        dataTermino: servicoDataTermino
      } = servicoUnidade ?? {};
      const {
        tipoBolsaNome,
        percentual,
        vigenciaDe,
        vigenciaAte,
        razaoStatus
      } = bolsaVigente ?? {};
      return (
        <div>
          <div className="panel-body fundoBranco">
            <div className="col-md-12 col-lg-12">
              <div className="row p-b-10">
                <fieldset className='p-b-10 p-t-10'>
                  <legend className="pull-left width-full">Informações do Responsável <Icon className="iconeAzul"
                                                                                            path={mdiAccountGroup}
                                                                                            size={1}/></legend>

                  {this.state.responsaveis.map((responsavel, index) => {
                    return (
                      <div className="row">
                        <Divider
                          variant="middle"
                          textAlign="left"
                          sx={{
                            mt: 2,
                            mb: 1
                          }}
                        >
                          {responsavel?.tipoDeContatoValor ? tipoDeContatoEnum[parseInt(responsavel?.tipoDeContatoValor)] ?? responsavel.nome : responsavel.nome}
                        </Divider>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>Conta (ERP)</label>
                            <input className="form-control" disabled value={responsavel.codigoErp}/>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>Nome</label>
                            <input className="form-control" disabled value={responsavel.nome}/>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>Data de Nascimento</label>
                            <input className="form-control" disabled
                                   value={_util.DataFormatada(responsavel.dataNascimento)}/>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>RG</label>
                            <input className="form-control" disabled value={responsavel.rg}/>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>CPF</label>
                            <input className="form-control" disabled value={responsavel.cpf}/>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>Telefone</label>
                            <input className="form-control" disabled value={responsavel.telefoneFixo}/>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>NPS</label>
                            <Stack
                              direction="row"
                              sx={{
                                background: '#edf0f5',
                                opacity: 0.6,
                                borderRadius: '3px',
                                height: '30px',
                                padding: '4px 8px',
                              }}
                            >
                              <span>{String(parseInt(responsavel?.npsScore ?? 0)).padStart(2, "0")} - {this.renderNpsScoreLabel(responsavel?.npsScore)}</span>
                              {responsavel?.npsScore !== null && (
                                <Box
                                  sx={{
                                    pl: 2
                                  }}
                                >
                                  {responsavel?.npsScore < 7 && <Icon path={mdiEmoticonSad} size={1} color="red" />}
                                  {(responsavel?.npsScore === 7 || responsavel?.npsScore === 8) && <Icon path={mdiEmoticonNeutral} size={1} color="yellow" />}
                                  {responsavel?.npsScore > 8 && <Icon path={mdiEmoticon} size={1} color="green" />}
                                </Box>
                              )}
                            </Stack>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>Inadimplência/Histórico de Inadimplência</label>
                            <Stack
                              direction="row"
                              sx={{
                                background: '#edf0f5',
                                opacity: 0.6,
                                borderRadius: '3px',
                                height: '30px',
                                padding: '2px 8px'
                              }}
                            >
                              <span>{responsavel?.inadimplente ? 'Sim' : 'Não'}</span>
                              <Box
                                sx={{
                                  pl: 3
                                }}
                              >
                                /
                              </Box>
                              <Stack
                                direction="row"
                                spacing={0}
                                alignItems="center"
                              >
                                {this.renderAdimplenteScore(responsavel?.scoreAcordo)}
                              </Stack>
                            </Stack>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>Rematrícula</label>
                            <input className="form-control" disabled value={responsavel?.rematricula !== null ? responsavel?.rematricula : 'Sem dados'}/>
                          </div>
                        </div>
                        <div className="col-md-4 p-b-10">
                          <div className="form-group">
                            <label>Ano / Onda</label>
                            <input className="form-control" disabled
                                   value={`${responsavel?.npsYear ?? '--'} / ${this.renderOnda(responsavel?.npsOnda)}`}/>
                          </div>
                        </div>
                        <br/>
                      </div>
                    )
                  })}

                  <hr/>
                </fieldset>

                <fieldset className='p-b-10 p-t-10'>
                  <legend className="pull-left width-full">Informações do Aluno <PersonFill
                    className='fa-icon iconeAzul'/></legend>
                  <div className="row">
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Conta (ERP)</label>
                        <input className="form-control" disabled value={codigoErp}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Nome</label>
                        <input className="form-control" disabled value={nome}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Data de Nascimento</label>
                        <input className="form-control" disabled
                               value={_util.DataFormatada(dataNascimento)}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>RG</label>
                        <input className="form-control" disabled value={rg}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>CPF</label>
                        <input className="form-control" disabled value={cpf}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Telefone</label>
                        <input className="form-control" disabled value={telefoneFixo}/>
                      </div>
                    </div>
                  </div>
                  <hr/>
                </fieldset>

                <fieldset className='p-b-10 p-t-10'>
                  <legend className="pull-left width-full">Informações da
                    Matrícula <FileEarmarkMedicalFill className='fa-icon iconeAzul'/></legend>

                  <div className="row">
                    <div className="col-md-3 p-b-10">
                      <div className="form-group">
                        <label>Matrícula (CRM)</label>
                        <input className="form-control" disabled value={codigoMatricula}/>
                      </div>
                    </div>
                    <div className="col-md-3 p-b-10">
                      <div className="form-group">
                        <label>Matrícula (ERP)</label>
                        <input className="form-control" disabled value={codigoMatriculaErp}/>
                      </div>
                    </div>
                    <div className="col-md-3 p-b-10">
                      <div className="form-group">
                        <label>Status da Matrícula</label>
                        <input className="form-control" disabled value={razaoStatus_Nome}/>
                      </div>
                    </div>
                    <div className="col-md-3 p-b-10">
                      <div className="form-group">
                        <label>Data da Matrícula</label>
                        <input className="form-control" disabled
                               value={_util.DataFormatada(dataDaMatricula)}/>
                      </div>
                    </div>

                    <div className="col-md-12 p-b-10 formatarTable">
                      <table className="table table-striped table-bordered formatarFonteTable">
                        <thead>
                        <tr>
                          <th>Serviço</th>
                          <th>Obrigatório</th>
                          <th>Configuração</th>
                          <th>Condição de pagamento</th>
                          <th>Valor do Contrato</th>
                          <th>Descontos de bolsa</th>
                          <th>Desconto Parcela Entrada ou à Vista</th>
                          <th>Desconto de método</th>
                          <th>Valor Líquido</th>
                          <th>Turma</th>
                          <th>Responsável Financeiro do Serviço</th>
                          <th>Plano de Pagamento</th>
                        </tr>
                        </thead>
                        <tbody>
                        {servicoMatriculaList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.itemIdName}</td>
                              <td>{item?.eObrigatorio}</td>
                              <td>{item?.configId}</td>
                              <td>{item?.paymTermId}</td>
                              <td>{"R$ " + _util.FormatarMoedaToLocale(item.unitPrice)}</td>
                              <td>{"R$ " + _util.FormatarMoedaToLocale(item.scholarshipValue)}</td>
                              <td>{"R$ " + _util.FormatarMoedaToLocale(item.totalDiscount)}</td>
                              <td>{"R$ " + _util.FormatarMoedaToLocale(item.discountMethod)}</td>
                              <td>{"R$ " + _util.FormatarMoedaToLocale(item.netValue)}</td>
                              <td>{item?.classes}</td>
                              <td>{item?.responsibleName}</td>
                              <td>{item?.planoDePagamento}</td>
                            </tr>
                          )
                        })}
                        </tbody>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th colSpan="3"
                              className="GridTotal">Total: {descricaoValorTotal ?? "R$ 0,00"}</th>
                        </tr>
                      </table>
                    </div>

                    <div className="col-md-3 p-b-10">
                      <div className="form-group">
                        <label>Data de Cancelamento</label>
                        <input className="form-control" disabled
                               value={_util.DataFormatada(dataDeCancelamento)}/>
                      </div>
                    </div>

                    <div className="col-md-9 p-b-10">
                      <div className="form-group">
                        <label>Motivo do Cancelamento</label>
                        <textarea className="form-control" disabled
                                  rows="4">{motivoDoCancelamento}</textarea>
                      </div>
                    </div>
                  </div>
                  <hr/>
                </fieldset>

                <fieldset className='p-b-10 p-t-10'>
                  <legend className="pull-left width-full">Informações da Unidade <Bank2
                    className='fa-icon iconeAzul'/></legend>
                  <div className="row">
                    <div className="col-md-6 p-b-10">
                      <div className="form-group">
                        <label>Unidade</label>
                        <input className="form-control" disabled value={unidadeNome}/>
                      </div>
                    </div>
                    <div className="col-md-6 p-b-10">
                      <div className="form-group">
                        <label>Código da Unidade (ERP)</label>
                        <input className="form-control" disabled value={unidadeCodigoErp}/>
                      </div>
                    </div>
                  </div>
                  <hr/>
                </fieldset>

                <fieldset className='p-b-10 p-t-10'>
                  <legend className="pull-left width-full">Informações do Curso <ReceiptCutoff
                    className='fa-icon iconeAzul'/></legend>
                  <div className="row">
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Código</label>
                        <input className="form-control" disabled
                               value={servicoCodigo}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Descrição</label>
                        <input className="form-control" disabled value={servicoNome}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Data de Início</label>
                        <input className="form-control" disabled
                               value={_util.DataFormatada(servicoDataInicio)}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Data Final</label>
                        <input className="form-control" disabled
                               value={_util.DataFormatada(servicoDataTermino)}/>
                      </div>
                    </div>
                  </div>
                  <hr/>
                </fieldset>

                <fieldset className='p-b-10 p-t-10'>
                  <legend className="pull-left width-full">Informações da Bolsa <HandbagFill
                    className='fa-icon iconeAzul'/></legend>
                  <div className="row">
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Tipo de Bolsa</label>
                        <input className="form-control" disabled
                               value={tipoBolsaNome}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>% de Bolsa</label>
                        <input className="form-control" disabled
                               value={percentual != null && percentual !== undefined ? percentual + "%" : ""}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Vigência (De)</label>
                        <input className="form-control" disabled
                               value={_util.DataFormatada(vigenciaDe)}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Vigência (Até)</label>
                        <input className="form-control" disabled
                               value={_util.DataFormatada(vigenciaAte)}/>
                      </div>
                    </div>
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Status</label>
                        <input className="form-control" disabled
                               value={razaoStatus}/>
                      </div>
                    </div>
                  </div>
                  <hr/>
                </fieldset>

                <fieldset className='p-b-10 p-t-10'>
                  <legend className="pull-left width-full">Histórico de Bolsas <FileEarmarkMedical
                    className='fa-icon iconeAzul'/></legend>
                  <div className="row">
                    <div className="p-10">
                      <table className="table table-striped table-bordered text-center">
                        <thead>
                        <tr>
                          <th>Tipo de Bolsa</th>
                          <th>% de Bolsa</th>
                          <th>Vigência (De)</th>
                          <th>Vigência (Até)</th>
                          <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {historicoDeBolsas.map((bolsa, index) => {
                          const {
                            tipoBolsaNome,
                            percentual,
                            vigenciaDe,
                            vigenciaAte,
                            razaoStatus
                          } = bolsa;
                          return (
                            <tr key={index}>
                              <td>{tipoBolsaNome}</td>
                              <td>{percentual + "%"}</td>
                              <td>{_util.DataFormatada(vigenciaDe)}</td>
                              <td>{_util.DataFormatada(vigenciaAte)}</td>
                              <td>{razaoStatus}</td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr/>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withUnidadeMatricula(Matricula, 'Detalhes');
